import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/',
		name: 'public',
		component:() => import('@/views/public/publicHome/PublicHome.vue'),
  },       
  {
    path: '/buscar-palavra-chave',
		name: 'buscarPalavraChave',
		component:() => import('@/views/public/buscaPalavraChave/BuscaPalavraChave.vue'),
  },                
	{
		path: '/lei/:id',
		name: 'lei',
		component: () => import('@/views/public/lei/Lei.vue')
	}
]

export default routes