import Vue from 'vue'
import Vuex from 'vuex'

import VuexPersist from 'vuex-persist'
import auth from './modules/auth'
import user from './modules/user'
import screen from './modules/screen'

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
});

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
		auth,
		user,
    screen
	},
	// plugins: [vuexLocalStorage.plugin],
})
