import { Scale, ScreenState } from './screen';

export default {
	setScale(state: ScreenState, scale: Scale): void {
		state.scale = scale;
	},
  setIsLoadFullPage(state: ScreenState, cond: boolean): void {
		state.isLoadFullPage = cond;
	},
  setIsShortSidebar(state: ScreenState, cond: boolean): void{
    state.isShortSidebar = cond;
  }
}