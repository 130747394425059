import { Auth, AuthState } from './auth';
import { setToken } from '@/services/api';

export default {
	setAuth(state: AuthState, auth: Auth): void {
		state.auth = auth
		setToken(auth.token)
	},
	setIsAuth(state: AuthState, isAuth: boolean): void {
		state.isAuth = isAuth
	},
	clearAuth(state: AuthState): void{
		state.auth.token = "",
		state.auth.rtoken = ""
	},
	setLoader(state: AuthState): void {
		state.loader = !state.loader
	}
}