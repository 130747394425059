import { Auth, AuthState } from './auth';

export default {
  auth(state: AuthState): Auth {
    return state.auth;
  },
	token(state: AuthState): string {
		return state.auth.token;
  },
	isAuth(state: AuthState): boolean {
		return state.isAuth;
	},
	loader(state: AuthState): boolean {
		return state.loader;
	},
	error(state: AuthState): boolean {
		return state.error;
	},
};