import { User, UserState } from './user';

export default {
  user(state: UserState): User {
    return state.user;
  },
	users(state: UserState): User[] {
		return state.users;
  },
	loader(state: UserState): boolean {
		return state.loader;
	},
	error(state: UserState): boolean {
		return state.error;
	},
  
  
};