import { Component, Vue } from 'vue-property-decorator'

const namespace = 'screen';
import { Action, Getter, Mutation } from 'vuex-class';



@Component({
	
})

export default class App extends Vue {

  @Mutation('setScale', { namespace }) setScale: any;

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  }

  handleResize(){
    const conf = {
      width: window.innerWidth,
      height: window.innerHeight
    }
    this.setScale(conf);
  }
  
}
