import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { UrlBase, Version, PortalName } from './config'
import Buefy from 'buefy'
import './assets/scss/app.scss'
import './assets/css/main.css'

Vue.use(Buefy)

Vue.config.productionTip = false

Vue.prototype.$urlBase = UrlBase()
Vue.prototype.$version = Version()
Vue.prototype.$portalName = PortalName

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
