import { Scale, ScreenState } from './screen';

export default {
  isLoadFullPage(state: ScreenState): boolean{
    return state.isLoadFullPage;
  },
  isShortSidebar(state: ScreenState): boolean{
    return state.isShortSidebar;
  },
  isMobile(state: ScreenState): boolean {
    const width = state.scale.width
    if(state.scale.width <= 480){
      return true;
    }
    return false;
  },
  isTablet(state: ScreenState): boolean {
    const width = state.scale.width
    if(width > 480 && width < 1024){
      return true;
    }
    return false;
  },
  isDesktop(state: ScreenState): boolean {
    if(state.scale.width >= 1024){
      return true;
    }
    return false;
  }
  
};