import { ActionContext } from 'vuex'
import { api, /*setToken, clearToken*/ } from '@/services/api'
import { ScreenState } from './screen';

export default {
  loadFullOn(state: ActionContext<ScreenState, string>): void {
		// state.isLoadFullPage = true;
    state.commit('setIsLoadFullPage', true);
	},
  loadFullOff(state: ActionContext<ScreenState, string>): void {
    console.log('pass');
    state.commit('setIsLoadFullPage', false);
	},
  toggleShortSideBar(state: ActionContext<ScreenState, string>): void {
    let cond = state.getters.isShortSidebar;
    cond = !cond;
    state.commit('setIsShortSidebar', cond);
  }
}