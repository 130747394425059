import { ActionContext } from 'vuex'
import { Auth, AuthState } from './auth'
import { api, clearToken } from '@/services/api'

export default {
	setAuth(store: ActionContext<AuthState, any>, auth: Auth): void {
    console.log('storee');
		// store.commit('setAuth', auth)
	},
	logout(store: ActionContext<AuthState, any>): void{
		clearToken()
		store.commit('setIsAuth', false)
		store.commit('clearAuth', false)
	},
}