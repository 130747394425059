// import { setToken } from '@/services/api';
import { UrlBase } from '@/config'
import axios from "axios";

export const api = axios.create({
	baseURL: UrlBase()
});
export function setToken(jwt: string): void {
	localStorage.setItem('user-token', jwt)
  api.defaults.headers.common["Accept-Language"] = 'pt-br';
  api.defaults.headers.common["Authorization"] = `bearer ${jwt}`;
}
export function getToken(): string {
	return `${localStorage.getItem('user-token')}`
}
export function verifyToken(): boolean {
  const token = getToken()
	if(token == '' || token == undefined || token == null || token == 'null'){
		return false
	} 
	setToken(token)
	return true
}
export function clearToken() {
	localStorage.removeItem('user-token')
  delete api.defaults.headers.common["Authorization"];
}