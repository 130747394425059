export const SYSTEM_PROD_MODE = false
//RC = Release Candidate
export const VERSION = 'Versão RC-1.0.0'

export const PortalName = "Portal Legislação"

export const API_BASE = {
	// DEV:'http://localhost:3000/',
	// PROD:'hhttp://localhost:3000/',
  DEV:'https://www3.laranjadaterra.es.gov.br/',
	PROD:'https://www3.laranjadaterra.es.gov.br/',
}

//UrlBase: (): string => {
export function UrlBase (): string {
	if(SYSTEM_PROD_MODE){
		return API_BASE.PROD
	} console.warn("[main] System in Devmode")
	return API_BASE.DEV
}

export function Version(): string{
	if(SYSTEM_PROD_MODE == false){
		return `${VERSION}-Devmode`
	} return VERSION
}