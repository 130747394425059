import Vue from 'vue'
import VueRouter from 'vue-router'

import { verifyToken } from '@/services/api'

import routes from './routes';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if(to.path == "/" || to.path == "/recuperarsenha" || to.name == 'responderCotacao'){
//     next();
//   }

//   else if(to.path != '/login'){
//     if(verifyToken()){
//       next();
//     }
//     else{
//       next('/login');
//     }
//   }
//   else if(to.path == '/login'){
//     if(verifyToken()){
//       next('/dashboard');
//     }
//     else{
//       next();
//     }
//   }

// })

export default router
