import { ActionContext } from 'vuex'
import { api, /*setToken, clearToken*/ } from '@/services/api'
import { /*User, */ UserState } from './user'

export default {
	fetchUsers(store: ActionContext<UserState, string>): void {
		api.get("/users/1").then((response) => {
			store.commit('setUser', response.data)
		})
	}
}